<template>
  <div>
    <div class="all_container">
      <div class="container">
        <div class="carousel">
          <!-- 中间的轮播图 -->
          <div class="carousel_right">
            <el-carousel trigger="click" height="450px" arrow="always">
              <el-carousel-item
                v-for="(item, index) in bannerArr1"
                :key="index"
              >
                <img
                  :src="item.thumb"
                  class="big_img"
                  @click="bannerArrChange(item)"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 信息分类区 -->
        <div class="main_body">
          <!-- 信息分类 -->
          <div class="info" style="padding: 88px 0 0 0">
            <div class="title">
              <div>{{ $t("home.InformationClassification") }}</div>
              <div class="border"></div>
            </div>
            <div class="classify">
              <div class="top">
                <div
                  class="top_box"
                  v-for="(item, index) in classify"
                  :key="index"
                  @click="goClassify(item)"
                >
                  <div class="img">
                    <img :src="item.thumb" alt="" />
                  </div>
                  <div class="top_box_border">
                    <div class="title">
                      {{ lang == "zh" ? item.zh_name : item.bnm_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bottom" @click="showMore" v-if="showMoreStatus"></div>
            </div>
          </div>

          <!-- 新楼盘 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.newLouPan") }}</div>
              <div class="border"></div>
            </div>
            <div class="loupan">
              <div
                class="loupan_box"
                v-for="(item, index) in new_house"
                :key="index"
                @click="goNewLoupan(item.id)"
              >
                <div class="loupan_box_top">
                  <img :src="item.thumb[0]" alt="" class="top_img" />
                </div>

                <div class="loupan_box_bottom">
                  <div class="top_box">
                    <div class="top_box_title">
                      {{ item.title }}
                    </div>
                    <img
                      :src="item.company_logo"
                      alt=""
                      class="top_box_img"
                      v-if="item.company_logo"
                    />
                  </div>

                  <div class="center_box">${{ item.price }}</div>
                  <div class="bottom_box">
                    <div class="bottom_box_left">
                      <img
                        src="@/assets/new_home_icons/address_icon.png"
                        alt=""
                      />
                      <div class="name">
                        {{ item.address }}
                      </div>
                    </div>
                    <div class="bottom_box_right">
                      <img
                        src="@/assets/new_home_icons/photo_icon.png"
                        alt=""
                      />
                      <div class="name">1/{{ item.thumb.length }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 最新发布 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.latestRelease") }}</div>
              <div class="border"></div>
            </div>
            <div class="release">
              <div
                class="release_box"
                v-for="(item, index) in new_list"
                :key="index"
                @click="goCommonInfo(item)"
              >
                <div class="release_box_top">
                  <img :src="item.thumb[0]" alt="" />
                </div>
                <div class="release_box_bootom">
                  <div class="title">{{ item.title }}</div>
                  <div class="address">
                    <div class="address_left">${{ item.price }}</div>
                    <div class="address_right">
                      <img
                        src="@/assets/new_home_icons/address_icon.png"
                        alt=""
                        class="img"
                      />
                      <div class="name">{{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 房地产 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.realEstate") }}</div>
              <div class="border"></div>
            </div>
            <div class="realty">
              <div
                class="release_box"
                v-for="(item, index) in house_list"
                :key="index"
                @click="goCommonInfo(item)"
              >
                <div class="release_box_top">
                  <img :src="item.thumb[0]" alt="" />
                </div>
                <div class="release_box_bootom">
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="address">
                    <div class="address_left">${{ item.price }}</div>
                    <div class="address_right">
                      <img
                        src="@/assets/new_home_icons/address_icon.png"
                        alt=""
                      />
                      <div class="name">{{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 车辆 -->
          <div class="info" style="padding-top: 88px">
            <div class="title">
              <div>{{ $t("home.car") }}</div>
              <div class="border"></div>
            </div>
            <div class="car">
              <div
                class="release_box"
                v-for="(item, index) in car_list"
                :key="index"
                @click="goCar(item)"
              >
                <div class="release_box_top">
                  <img :src="item.thumb[0]" alt="" />
                </div>
                <div class="release_box_bootom">
                  <div class="title">
                    {{ item.title }}
                  </div>
                  <div class="address">
                    <div class="address_left">${{ item.price }}</div>
                    <div class="address_right">
                      <img src="@/assets/new_home_icons/address.png" alt="" />
                      <div class="name">{{ item.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      src="../../../assets/loading.gif"
      class="loadingImg"
      v-if="loadingValue == true"
    />

    <!-- 公告弹窗 -->
    <el-dialog title="" :visible.sync="outerVisible">
      <!-- <div class="xitongTitle">{{ $t("systemInformation") }}</div>
      <div class="check_btn" @click="goCheck">{{ $t("clickForDetails") }}</div> -->
      <img
        src="@/assets/new_home_icons/close.png"
        alt=""
        class="close"
        @click="outerVisible = false"
      />
      <!-- <img src="@/assets/new_home_icons/gonggao.png" alt=""> -->
    </el-dialog>
  </div>
</template>

<script>
import houseItem from "@/components/houseItem.vue";

export default {
  components: {
    houseItem,
  },
  data() {
    return {
      classifyStatus: "",
      recommendList: [], //热门推荐列表
      likeList: [], //喜欢列表

      total: 8, //总条数
      currentPage: 1, //当前页数
      pageSize: 16, //每页的条数
      pageSizes: [16],

      bannerArr1: [], //轮播图1
      bannerArr2: [],
      bannerArr3: [],
      bannerArr4: [],
      bannerArr5: [],
      bannerArr6: [],
      bannerArr7: [],

      classify: [],

      house_list: [], // 房地产列表
      new_house: [], //  新楼盘列表
      new_list: [], //  最新发布列表
      car_list: [], // 车辆买卖列表

      lang: "",
      vip: "",
      loadingValue: false,
      unReadCount: "", //未读消息数量
      outerVisible: false,

      showMoreStatus: true,
    };
  },
  created() {
    console.log("打开");
    console.log("关闭");
    this.lang = localStorage.getItem("lang");
    this.getHomeList();
    // this.getRecommend();
    // this.getAllLike();
    this.getAds(1, 3);

    this.getCateList(null);
    this.getUnread();

    // 实现监听localstorage中某个键对应的值的变化
    //根据自己需要来监听对应的key
    window.addEventListener("setItemEvent", (e) => {
      //e.key : 是值发生变化的key
      //例如 e.key==="token";
      //e.newValue : 是可以对应的新值
      if (e.key === "lang") {
        this.lang = e.newValue;
      }
    });

    this.getUserInfo();
  },

  mounted() {},
  methods: {
    // 获取未读消息
    getUnread() {
      this.$http.getUnread().then((res) => {
        if (res.code == 1) {
          this.unReadCount = res.data.num;
          if (Number(this.unReadCount) > 0) {
            this.outerVisible = true;
          } else {
            this.outerVisible = false;

            console.log(this.outerVisible, 1111);
          }
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    goCheck() {
      this.$router.push({
        path: "/myMessage",
      });
    },
    // 跳转到对应分类页
    goClassify(item) {
      console.log(item);
      if (item.id == 12) {
        console.log(item.id);
        // 房地产
        this.$router.push({
          // 房地产分类
          path: "/houseClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      } else if (item.id == 159) {
        // 新楼盘
        this.$router.push({
          // 新楼盘分类
          path: "/realtyClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      } else if (item.id == 3) {
        // 二手车
        this.$router.push({
          // 二手车分类
          path: "/carClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      } else if (item.id == "jingjiren") {
        // 经纪人
        this.$router.push({
          //  经纪人分类
          path: "/brokerClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      } else {
        // 普通分类
        this.$router.push({
          // 普通分类
          path: "/commonClassify",
          query: {
            pid: item.id,
            zh_name: item.zh_name,
            bnm_name: item.bnm_name,
          },
        });
      }
      // this.$router.push(
      // 	{
      // 		房地产分类
      // 		path: '/realtyClassify',

      // 		普通分类
      // 		path: '/commonClassify',

      // 		经纪人分类
      // 		path: '/brokerClassify',

      // 		query: {
      // 			Classify: item,
      // 		}
      // 	}
      // )
    },
    // 获取分类信息
    getCateList() {
      this.$http.getCate().then((res) => {
        if (res.code == 1) {
          this.spClassify = res.data;
          console.log(this.classify, 11111);
          this.classify = this.spClassify.splice(0, 12);
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    showMore() {
      if (this.showMoreStatus) {
        this.classify = [...this.classify, ...this.spClassify];
      }
      this.showMoreStatus = false;
    },
    // 轮播图切换事件
    bannerArrChange(item) {
      console.log(item);
      let ads_type = item.ads_type;
      let bind_information_id = item.bind_information_id;
      let href = item.href;
      if(href != ''){
        window.open('https://' + href)
        return
      }
      if (bind_information_id != 0) {
        if (item.url == 1) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else if (item.url == 2) {
          this.$router.push({
            path: "/particulars",
            query: {
              id: bind_information_id,
              url: item.url,
            },
          });
        } else {
          this.$router.push({
            // 普通页
            path: "/details",
            query: {
              id: item.bind_information_id,
            },
          });
        }
      } else {
        window.open('https://' +  href);
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$http.userInfo().then((res) => {
        if (res.code == 1) {
          this.vip = res.data.vip;
        } else if (res.code == "-201") {
          console.log(123);
          this.$store.commit("edituserinfo", res.data);
        } else {
          console.log(123444);
          this.$message.error(res.msg);
          this.$store.commit("edituserinfo", res.data);
        }
      });
    },
    // 获取轮播图
    getAds(type, typeid) {
      console.log(type, "快捷键了");
      this.$http
        .getAds({
          typeid,
        })
        .then((res) => {
          if (res.code == 1) {
            switch (type) {
              case 1:
                this.bannerArr1 = res.data;
                break;
              case 2:
                this.bannerArr2 = res.data;
                break;
              case 3:
                this.bannerArr3 = res.data;
                break;
              case 4:
                this.bannerArr4 = res.data;
                break;
              case 5:
                this.bannerArr5 = res.data;
                break;
              case 6:
                this.bannerArr6 = res.data;
                break;
              case 7:
                this.bannerArr7 = res.data;
                break;
            }
          } else {
            this.$message.info(res.msg);
          }
        });
    },

    // 获取首页全部分类
    getHomeList() {
      this.$http.homeInfoList().then((res) => {
        if (res.code == 1) {
          console.log(res.data, "首页全部数据");
          this.house_list = res.data.house_list;
          this.new_house = res.data.new_house;
          this.new_list = res.data.new_list;
          this.car_list = res.data.car_list;
        } else {
          this.$message.info(res.data.msg);
        }
      });
    },

    // 去新楼盘详情页
    goNewLoupan(id) {
      console.log(id, 11234);
      this.$router.push({
        // 房地产分类
        path: "/particulars",
        query: {
          id: id,
        },
      });
    },

    // 去对应详情页
    goCommonInfo(item) {
      if (item.url == 1) {
        this.$router.push({
          // 房地产详情页
          path: "/particulars",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else if (item.url == 2) {
        this.$router.push({
          // 车辆详情页
          path: "/particulars",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else {
        this.$router.push({
          // 普通页
          path: "/details",
          query: {
            id: item.id,
          },
        });
      }
    },

    goCar(item) {
      if (item.url == 1) {
        this.$router.push({
          // 房地产详情页
          path: "/",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else if (item.url == 2) {
        this.$router.push({
          // 车辆详情页
          path: "/particulars",
          query: {
            id: item.id,
            url: item.url,
          },
        });
      } else {
        this.$router.push({
          // 普通页
          path: "/details",
          query: {
            id: item.id,
          },
        });
      }
    },
    // // 	去热门推荐
    // toHotRecommand() {
    // 	// let types = 1;
    // 	this.$router.push({
    // 		path: '/hotRecommand',
    // 		// query:{
    // 		//     pages:types,
    // 		// }
    // 	});
    // 	// window.open('/hotRecommand')
    // 	// let routeUrl=this.$router.resolve({
    // 	// 	path: '/home/hotRecommand'
    // 	// })
    // 	// window.open(routeUrl.href,'_blank')
    // },
    // // 获取热门推荐
    // getRecommend() {
    // 	this.$http.getRecommend({
    // 		pageNo: 1,
    // 		pageSize: 24,
    // 		is_index: 1,
    // 		type: 1,
    // 	}).then(res => {
    // 		if (res.code == 1) {
    // 			this.recommendList = res.data.data;
    // 			this.$forceUpdate();
    // 		} else {
    // 			this.$message.error(res.data.msg);
    // 		}
    // 		// console.log(res);
    // 	}).catch((v) => {
    // 		console.log(v);
    // 	});
    // },
    // // 获取所有喜欢的
    // getAllLike() {
    // 	let data = {
    // 		pageNo: this.currentPage,
    // 		pageSize: this.pageSize,
    // 		is_index: 1,
    // 		type: 2,
    // 	};
    // 	this.loadingValue = true;
    // 	this.$http.getRecommend(data).then(res => {
    // 		this.loadingValue = false;
    // 		window.scrollTo(0, 0)
    // 		console.log(res);
    // 		if (res.code == 1) {
    // 			this.total = res.data.total;
    // 			this.likeList = res.data.data;
    // 		} else {
    // 			this.$message.info(res.msg);
    // 		}
    // 	}).catch(() => {
    // 		this.loadingValue = false;
    // 	})
    // },

    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
      this.getAllLike();
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
      this.getAllLike();
    },
  },
};
</script>

<style lang="less" scoped="scoped">
.big_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.all_container {
  background: #f6f7f9;
}

.container {
  width: 100%;
  // height: 446px;
  margin: 0 auto;
  padding-bottom: 64px;
}

.carousel {
  // margin-top: 36px;
  display: flex;

  .carousel_left {
    width: 232px;
  }

  .carousel_right {
    width: 100%;
    margin: 0 auto;
  }
}

.main_body {
  // 信息分类
  .info {
    padding-top: 88px;
    background: #f6f7f9;
    padding: 0 36px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;

      .border {
        width: 40px;
        height: 4px;
        background: rgba(243, 152, 0);
        border-radius: 2px;
        margin-top: 8px;
      }
    }

    .classify {
      position: relative;
      background-color: #fff;
      // height: 460px;
      width: 100%;
      box-sizing: border-box;

      .top {
        margin-top: 32px;
        padding: 71px 32px 0;
        // display: grid;
        // justify-content: center;
        // grid-template-columns: 16% 16% 16% 16% 16% 16%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .top_box {
          width: 16%;
          position: relative;
          margin-bottom: 48px;
          margin-right: 10px;

          .img {
            position: absolute;
            top: -30%;
            left: 50%;
            transform: translatex(-60%);
            width: 70px;
            height: 70px;

            // background-color: pink;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .top_box_border {
            // width: 280px;
            height: 105px;
            border-radius: 5px;
            // background-color: pink;
            border: 1px solid #ff8100;
            border-bottom: 6px solid #ff8100;

            .title {
              margin-top: 50px;
              font-size: 22px;
              text-align: center;
            }
          }
        }
      }

      .bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 48px;
        background-image: url("../../../assets/new_home_icons/bottom.png");
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .loupan {
      width: 100%;
      // display: grid;
      // justify-content: space-between;
      // grid-template-columns: 34% 34% 34%;
      display: flex;
      justify-content: space-between;

      // .loupan_box:nth-child(3n) {
      // 	margin-right: 0 !important;
      // }

      .loupan_box {
        width: 33%;
        min-height: 570px;
        background: #fff;
        margin-top: 56px;
        // margin-right: 28px;

        .loupan_box_top {
          width: 100%;
          height: 320px;

          .top_img {
            width: 100%;
            height: 100%;
          }
        }

        .loupan_box_bottom {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          min-height: 152px;
          padding: 0 16px;

          .top_box {
            display: flex;
            justify-content: space-between;

            .top_box_title {
              width: 440px;
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #333333;
              text-align: left;
            }

            .top_box_img {
              width: 150px;
              height: 150px;
            }
          }

          .center_box {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #f39800;
            text-align: left;
          }

          .bottom_box {
            display: flex;
            justify-content: space-between;

            .bottom_box_left {
              display: flex;
              justify-content: left;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                word-wrap: break-word;
              }
            }

            .bottom_box_right {
              display: flex;
              justify-content: right;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
              }
            }
          }
        }
      }
    }

    .release {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 56px;

      .release_box {
        width: 296px;
        min-height: 370px;
        background: #fff;
        text-align: left;
        margin-bottom: 10px;

        .release_box_top {
          // width: 310px;
          // height: 310px;

          img {
            width: 296px;
            height: 260px;
          }
        }

        .release_box_bootom {
          min-height: 110px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: left;
          }

          .address {
            display: block;

            .address_left {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #f39800;
              margin-right: 10px;
              margin-bottom: 10px;
            }

            .address_right {
              display: flex;
              // justify-content: space-between;
              justify-content: left;
              align-items: center;
              word-wrap: break-word;

              .img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }

    .realty {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 56px;

      .release_box {
        width: 24%;
        min-height: 420px;
        background: #fff;

        .release_box_top {
          width: 100%;
          height: 320px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .release_box_bootom {
          min-height: 89px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: left;
          }

          .address {
            display: block;

            .address_left {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #f39800;
              margin-right: 10px;
              margin-bottom: 10px;
              text-align: left;
            }

            .address_right {
              display: flex;
              // justify-content: space-between;
              justify-content: left;
              align-items: center;
              word-wrap: break-word;

              img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }

    .car {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 56px;

      .release_box {
        width: 24%;
        height: 420px;
        background: #fff;

        .release_box_top {
          width: 100%;
          height: 320px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .release_box_bootom {
          min-height: 89px;
          padding: 0 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            text-align: left;
          }

          .address {
            display: block;

            .address_left {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #f39800;
              text-align: left;
              margin-bottom: 5px;
            }

            .address_right {
              display: flex;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
              }

              .name {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }
  }
}

.carousel_left {
  width: 232px;

  /deep/ .el-carousel__arrow {
    top: 72%;
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

.carousel_left2 {
  width: 232px;

  /deep/ .el-carousel__arrow {
  }

  /deep/ .el-carousel__arrow--left,
  /deep/ .el-carousel__arrow--right {
    width: 24px;
    height: 24px;
  }
}

/deep/.el-carousel__indicators--horizontal {
  .el-carousel__indicator--horizontal button {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border-radius: 50%;
    opacity: 0.5;
  }

  .el-carousel__indicator--horizontal.is-active button {
    width: 24px;
    height: 8px;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 10px;
  }
}

.pagination {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

// 新增的样式
.loading {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0rpx;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingImg {
  width: 100rpx;
  height: 100rpx;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
}

/deep/.el-dialog {
  position: relative;
  width: 400px;
  height: 472px;
  background-image: url("../../../assets/new_home_icons/gonggao.jpg");
  background-size: contain;
}

/deep/.el-dialog__header {
  padding: 0;
}

.xitongTitle {
  position: absolute;
  top: 95px;
  left: 80px;
  color: #fff;
  font-size: 26px;
}

.check_btn {
  position: absolute;
  top: 180px;
  left: 80px;
  color: #fff;
  font-size: 20px;
}

.check_btn:hover {
  cursor: pointer;
}

/deep/.el-icon-close {
  display: none;
}

.close {
  width: 40px;
  height: 40px;
  margin-top: 500px;
}
</style>
